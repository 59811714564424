import React from 'react';
import { Github } from 'lucide-react';
import { ThemeToggle } from './ThemeToggle';
import { UserMenu } from './UserMenu';
import { User } from '../types';

interface HeaderProps {
  isAuthenticated: boolean;
  user: User | null;
  onLogin: () => void;
}

export const Header: React.FC<HeaderProps> = ({ isAuthenticated, user, onLogin }) => {
  return (
    <header className="border-b dark:border-gray-700 p-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Github className="w-8 h-8" />
          <h1 className="text-xl font-bold">GitHub Chat</h1>
        </div>
        <div className="flex items-center space-x-4">
          <ThemeToggle />
          {!isAuthenticated ? (
            <button
              onClick={onLogin}
              className="px-4 py-2 rounded-md bg-gray-900 dark:bg-white text-white dark:text-gray-900 hover:opacity-90"
            >
              Se connecter avec GitHub
            </button>
          ) : (
            <UserMenu />
          )}
        </div>
      </div>
    </header>
  );
};