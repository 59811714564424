import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Message, User } from '../types';
import { v4 as uuidv4 } from 'uuid';

interface ChatState {
  activeChat: number | null;
  messages: Record<number, Message[]>;
  setActiveChat: (userId: number) => void;
  sendMessage: (content: string, sender: User, recipientId: number) => void;
  getMessages: (userId: number) => Message[];
}

export const useChatStore = create<ChatState>()(
  persist(
    (set, get) => ({
      activeChat: null,
      messages: {},

      setActiveChat: (userId: number) => {
        set({ activeChat: userId });
      },

      sendMessage: (content: string, sender: User, recipientId: number) => {
        const message: Message = {
          id: uuidv4(),
          content,
          sender,
          timestamp: new Date(),
        };

        set((state) => ({
          messages: {
            ...state.messages,
            [recipientId]: [...(state.messages[recipientId] || []), message],
          },
        }));
      },

      getMessages: (userId: number) => {
        return get().messages[userId] || [];
      },
    }),
    {
      name: 'chat-storage',
      partialize: (state) => ({
        messages: state.messages
      })
    }
  )
);