import React, { useState } from 'react';
import { Search, UserPlus, X } from 'lucide-react';
import { useContactsStore } from '../store/useContactsStore';
import { useChatStore } from '../store/useChatStore';
import { User } from '../types';

export const ContactsList: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const { contacts, addContact, removeContact, searchUsers } = useContactsStore();
  const { activeChat, setActiveChat } = useChatStore();

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    if (query.length >= 3) {
      const results = await searchUsers(query);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleAddContact = async (username: string) => {
    try {
      await addContact(username);
      setSearchQuery('');
      setSearchResults([]);
    } catch (error) {
      alert('Erreur lors de l\'ajout du contact');
    }
  };

  return (
    <div className="w-64 border-r dark:border-gray-700 h-full flex flex-col">
      <div className="p-4 border-b dark:border-gray-700">
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Rechercher un utilisateur..."
            className="w-full p-2 pr-8 rounded-md border dark:border-gray-700 bg-white dark:bg-gray-800"
          />
          <Search className="absolute right-2 top-2.5 w-4 h-4 text-gray-400" />
        </div>
      </div>

      {searchResults.length > 0 && (
        <div className="p-2 bg-gray-50 dark:bg-gray-800">
          <h3 className="text-sm font-medium mb-2">Résultats de recherche</h3>
          {searchResults.map((user) => (
            <div key={user.id} className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md">
              <div className="flex items-center">
                <img src={user.avatar_url} alt={user.login} className="w-8 h-8 rounded-full" />
                <span className="ml-2 text-sm">{user.login}</span>
              </div>
              <button
                onClick={() => handleAddContact(user.login)}
                className="p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full"
              >
                <UserPlus className="w-4 h-4" />
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="flex-1 overflow-y-auto">
        <h3 className="p-4 text-sm font-medium">Contacts</h3>
        {contacts.map((contact) => (
          <div
            key={contact.id}
            className={`flex items-center justify-between p-4 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer ${
              activeChat === contact.id ? 'bg-gray-100 dark:bg-gray-800' : ''
            }`}
            onClick={() => setActiveChat(contact.id)}
          >
            <div className="flex items-center">
              <img src={contact.avatar_url} alt={contact.login} className="w-10 h-10 rounded-full" />
              <span className="ml-3 font-medium">{contact.login}</span>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                removeContact(contact.id);
              }}
              className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};