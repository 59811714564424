import React from 'react';
import { useAuthStore } from '../store/useAuthStore';
import { useContactsStore } from '../store/useContactsStore';
import { useChatStore } from '../store/useChatStore';
import { ChatMessage } from './ChatMessage';
import { ChatInput } from './ChatInput';

export const ChatWindow: React.FC = () => {
  const { user } = useAuthStore();
  const { contacts } = useContactsStore();
  const { activeChat, messages, sendMessage, getMessages } = useChatStore();

  const activeContact = contacts.find(contact => contact.id === activeChat);
  const chatMessages = activeChat ? getMessages(activeChat) : [];

  const handleSendMessage = (content: string) => {
    if (user && activeChat) {
      sendMessage(content, user, activeChat);
    }
  };

  if (!activeChat || !activeContact) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <p className="text-gray-500">Sélectionnez un contact pour commencer à discuter</p>
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col">
      <div className="border-b dark:border-gray-700 p-4">
        <div className="flex items-center">
          <img src={activeContact.avatar_url} alt={activeContact.login} className="w-10 h-10 rounded-full" />
          <span className="ml-3 font-medium">{activeContact.login}</span>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-4">
        {chatMessages.map((message) => (
          <ChatMessage
            key={message.id}
            message={message}
            isOwnMessage={message.sender.id === user?.id}
          />
        ))}
      </div>

      <ChatInput onSendMessage={handleSendMessage} />
    </div>
  );
};