import React from 'react';
import { useAuthStore } from './store/useAuthStore';
import { Header } from './components/Header';
import { WelcomeScreen } from './components/WelcomeScreen';
import { ContactsList } from './components/ContactsList';
import { ChatWindow } from './components/ChatWindow';

function App() {
  const { isAuthenticated, login, user } = useAuthStore();

  const handleLogin = async () => {
    const token = prompt('Entrez votre token GitHub:');
    if (token) {
      try {
        await login(token);
      } catch (error) {
        console.error('Erreur de connexion:', error);
        alert('Erreur de connexion. Vérifiez votre token.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
      <Header 
        isAuthenticated={isAuthenticated} 
        user={user} 
        onLogin={handleLogin}
      />

      {isAuthenticated ? (
        <main className="container mx-auto p-4 h-[calc(100vh-5rem)]">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg flex h-full">
            <ContactsList />
            <ChatWindow />
          </div>
        </main>
      ) : (
        <WelcomeScreen />
      )}
    </div>
  );
}

export default App;