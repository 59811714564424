import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Message } from '../types';

interface ChatMessageProps {
  message: Message;
  isOwnMessage: boolean;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({ message, isOwnMessage }) => {
  return (
    <div className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'} mb-4`}>
      <div className={`flex ${isOwnMessage ? 'flex-row-reverse' : 'flex-row'} items-start max-w-[70%]`}>
        <img
          src={message.sender.avatar_url}
          alt={message.sender.login}
          className="w-8 h-8 rounded-full"
        />
        <div className={`mx-2 ${isOwnMessage ? 'bg-blue-600 text-white' : 'bg-gray-100 dark:bg-gray-800'} rounded-lg p-3`}>
          <div className="flex items-center mb-1">
            <span className="font-medium text-sm">{message.sender.login}</span>
            <span className="text-xs ml-2 opacity-60">
              {formatDistanceToNow(message.timestamp, { addSuffix: true })}
            </span>
          </div>
          <p className="text-sm whitespace-pre-wrap">{message.content}</p>
        </div>
      </div>
    </div>
  );
};