import React from 'react';

export const WelcomeScreen: React.FC = () => {
  return (
    <div className="container mx-auto p-4 text-center">
      <h2 className="text-2xl font-bold mb-4">
        Bienvenue sur GitHub Chat
      </h2>
      <p className="text-gray-600 dark:text-gray-400">
        Connectez-vous avec votre compte GitHub pour commencer à discuter.
      </p>
    </div>
  );
};