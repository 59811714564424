import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Octokit } from '@octokit/rest';
import { User } from '../types';

interface AuthState {
  user: User | null;
  token: string | null;
  octokit: Octokit | null;
  isAuthenticated: boolean;
  login: (token: string) => Promise<void>;
  logout: () => void;
  updateProfile: (updates: Partial<User>) => Promise<void>;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      token: null,
      octokit: null,
      isAuthenticated: false,

      login: async (token: string) => {
        const octokit = new Octokit({ auth: token });
        const { data: user } = await octokit.users.getAuthenticated();
        set({ 
          user: {
            id: user.id,
            login: user.login,
            avatar_url: user.avatar_url,
            name: user.name || '',
            bio: user.bio || '',
            email: user.email || '',
          },
          token,
          octokit, 
          isAuthenticated: true 
        });
      },

      logout: () => {
        set({ user: null, token: null, octokit: null, isAuthenticated: false });
      },

      updateProfile: async (updates: Partial<User>) => {
        const { octokit, user } = get();
        if (!octokit || !user) return;

        try {
          const { data: updatedUser } = await octokit.users.updateAuthenticated({
            name: updates.name,
            bio: updates.bio,
            email: updates.email,
          });

          set({
            user: {
              ...user,
              name: updatedUser.name || user.name,
              bio: updatedUser.bio || user.bio,
              email: updatedUser.email || user.email,
            }
          });
        } catch (error) {
          console.error('Error updating profile:', error);
          throw error;
        }
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ 
        token: state.token,
        user: state.user,
        isAuthenticated: state.isAuthenticated
      })
    }
  )
);