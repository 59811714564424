import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '../types';
import { useAuthStore } from './useAuthStore';

interface ContactsState {
  contacts: User[];
  addContact: (username: string) => Promise<void>;
  removeContact: (userId: number) => void;
  searchUsers: (query: string) => Promise<User[]>;
}

export const useContactsStore = create<ContactsState>()(
  persist(
    (set, get) => ({
      contacts: [],
      
      addContact: async (username: string) => {
        const octokit = useAuthStore.getState().octokit;
        if (!octokit) return;

        try {
          const { data: user } = await octokit.users.getByUsername({ username });
          const newContact = {
            id: user.id,
            login: user.login,
            avatar_url: user.avatar_url,
            name: user.name || '',
            bio: user.bio || '',
            email: user.email || '',
          };
          
          set((state) => ({
            contacts: [...state.contacts, newContact]
          }));
        } catch (error) {
          console.error('Error adding contact:', error);
          throw error;
        }
      },

      removeContact: (userId: number) => {
        set((state) => ({
          contacts: state.contacts.filter((contact) => contact.id !== userId)
        }));
      },

      searchUsers: async (query: string) => {
        const octokit = useAuthStore.getState().octokit;
        if (!octokit || query.length < 3) return [];

        try {
          const { data } = await octokit.search.users({
            q: query,
            per_page: 5
          });

          return data.items.map(user => ({
            id: user.id,
            login: user.login,
            avatar_url: user.avatar_url,
            name: user.name || '',
            bio: user.bio || '',
            email: user.email || '',
          }));
        } catch (error) {
          console.error('Error searching users:', error);
          return [];
        }
      }
    }),
    {
      name: 'contacts-storage'
    }
  )
);